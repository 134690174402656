@import "@/assets/styles/global/lib.scss";



































.currency-block-outer {
  min-height: size(60);
  position: -webkit-sticky;
  position: sticky;
  top: 5.5rem;
  z-index: var(--senior-menu-z-index);
  @media screen and (min-width: $breakpoint-md) {
    top: 7.5rem;
  }
  .currency-block {
    background: rgba(255, 255, 255);
    box-shadow: 0 size(2) size(4) 0 rgba(0, 0, 0, 0.1);
    border-radius: size(3);
    padding: size(8) size(12) size(8) size(12);
    min-width: size(104);
    float: right;
    position: relative;
    &.bordered {
      border: size(1) solid var(--rs-black-color);
      font-weight: bold;
    }
    @media screen and (min-width: $breakpoint-md) {
      padding: size(16) size(22) size(16) size(22);
    }

    .currency-symbol {
      width: size(30);
      height: size(30);
      margin-top: size(5);
      margin-right: size(5);
      border-radius: size(15);
      border: size(1) solid var(--regular-text);
      color: var(--regular-text);
      text-align: center;
      position: relative;
      line-height: size(30);
    }
    .currency-block-right {
      width: 100%;
      cursor: pointer;
      label {
        display: none;
      }
      .show-in {
        display: block;
        font-size: size(12);
        font-family: var(--regular-font);
        line-height: 1;
        position: relative;
        margin-top: size(5);
      }
      select {
        display: block;
        width: size(50);
        padding: size(7) size(5) 0 0;
        line-height: 1;
        font-size: size(12);
        font-weight: 700;
        -webkit-appearance: none;
        border-radius: 0;
        &::-ms-expand {
          display: none;
        }
        appearance: none;
        background: url('../../../assets/icon-dropdown-blk.svg') no-repeat transparent;
        background-size: size(9) size(6);
        background-position: bottom size(3) right size(4);
        border: none;
        box-shadow: none;
        outline: none !important;
        position: relative;
        z-index: 1;
      }
    }
    p {
      font-size: size(12);
      line-height: size(17);
      font-weight: 500;
      padding-top: size(2.5);
      padding-bottom: size(2.5);
      position: relative;
      outline: 0;
      img {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        transition: all .3s ease-in-out;
        &.rotate {
          transform: translateY(-50%) rotate(180deg);
        }
      }
      &:hover {
        color: var(--ca-btn-bg);
      }
    }
  }

  .dropdown-list {
    margin-top: size(5);
    position: absolute;
    right: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.9);
    padding: 0 size(12) size(8) size(12);
    height: #{size(0)};
    transition: height .3s, visibility .3s;
    transform: translateY(-6px);
    cursor: default;
    @media screen and (min-width: $breakpoint-md) {
      padding: 0 size(22) size(16) size(22);
    }
    &.add-height {
      height: #{size(70)};
      box-shadow: 0 size(3) size(4) 0 rgba(0, 0, 0, 0.1);
      background: var(--rs-white-color);
    }
    .list-inner {
      height: 100%;
      overflow: hidden;
      &.add-border{
        border-top: 1px solid var(--regular-text);
      }
    }
    p {
      padding: size(15) 0 0;
      &:first-child {
        padding-top: size(15);
      }
      &:last-child {
        padding-bottom: 0;
      }
      &:hover {
        color: var(--ca-btn-bg);
        cursor: pointer;
      }
      @media screen and (min-width: $breakpoint-lg) {
        padding: size(10) 0 0;
      }
    }
  }
}
