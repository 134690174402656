@import "@/assets/styles/global/lib.scss";


















































.page-placeholder {
  --title-placeholder-width: #{size(320)};
  --placeholder-lines-height: #{size(20)};
  div[class*='placeholder'],
  li {
    background: var(--loading-placeholder-bg-lite);
    animation: placeholder-move 0.5s infinite;
  }

  .title-placeholder {
    max-width: var(--title-placeholder-width);
    height: size(40);
    margin-bottom: size(20);
    padding-right: var(--junior-menu-pad-right);
    @media screen and (min-width: $breakpoint-lg) {
      height: size(64);
      margin-bottom: size(60);
    }
  }

  .title-2-placeholder {
    max-width: var(--title-placeholder-width);
    height: size(38);
    margin-bottom: var(--placeholder-lines-height);
    @media screen and (min-width: $breakpoint-lg) {
      height: size(44);
    }
  }

  .placeholder-line {
    height: var(--placeholder-lines-height);
    margin: size(10) 0;
  }

  .descrition-block {
    margin-bottom: 50%;
    @media screen and (min-width: $breakpoint-lg) {
      margin-bottom: size(370);
    }
    padding-right: var(--junior-menu-pad-right);
  }

  .methods-block {
    padding-right: var(--junior-menu-pad-right);
    margin-bottom: size(60);
    border-bottom: 2px solid var(--loading-placeholder-bg-lite);
    ul {
      font-size: 0;
      li {
        height: var(--placeholder-lines-height);
        display: inline-block;
        margin: 0 size(8) size(12);
        position: relative;
        &:first-child {
          margin-left: 0;
        }
        &.border {
          &:after {
            content: '';
            display: block;
            border-bottom: size(5) solid var(--loading-placeholder-bg-lite);
            position: absolute;
            bottom: -size(12);
            height: size(5);
            width: 100%;
          }
        }
      }
    }
  }

  .data-block {
    padding-right: var(--junior-menu-pad-right);
  }

  .footer {
    background: var(--box-color-1);
    height: size(240);
    margin-top: size(32);
    margin-left: var(--section-box-pad-left);
  }
  @media screen and (min-width: $breakpoint-lg) {
    --title-placeholder-width: #{size(649)};
    .w-1020 {
      max-width: size(1020);
    }
    .w-800 {
      max-width: size(800);
    }
  }

  @media screen and (min-width: $breakpoint-md) {
    --title-placeholder-width: #{size(500)};
    .w-760 {
      max-width: size(760);
    }

    .w-656 {
      max-width: size(656);
    }
  }

  .w-200 {
    max-width: size(200);
  }

  .w-220 {
    width: size(220);
  }

  .w-100 {
    width: size(100);
  }

  .w-90 {
    width: size(90);
  }

  .w-85 {
    width: size(85);
  }

  @media screen and (max-width: $breakpoint-md) {
    .methods-block {
      ul {
        li:last-child {
          display: none;
        }
      }
    }
  }
}

@keyframes placeholder-move {
  from {
    background-color: rgba(211, 215, 228, 0.5);
  }
  to {
    background-color: var(--loading-placeholder-bg-lite);
  }
}
