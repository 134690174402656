@import "@/assets/styles/global/lib.scss";











































































































































































































































































































































.nav-main {
  .with-sub-menu {
    .endpoint-submenu-container {
      @media screen and (min-width: $breakpoint-md) {
        display: none;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }
      @media screen and (min-width: $breakpoint-lg) {
        transform: translateX(-50%);
      }
    }
    @media screen and (min-width: $breakpoint-md) {
      &:hover .endpoint-submenu-container {
        display: block;
      }
    }
  }
}
